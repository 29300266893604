<template>
  <v-app-bar height="50" color="white" scroll-threshold="400" class="jw-header-pc" v-if="!isWritePage">
    <div class="jw-gnb jw-gnb--wrap">
      <!-- <NuxtLink to="/pub/today" class="jw-gnb__logo"> -->
      <NuxtLink to="/today" class="jw-gnb__logo">
        <img src="~/assets/images/header-logo.svg" width="128" height="37" alt="Journal with Jesus logotype" class="jw-header-pc__logo" />
      </NuxtLink>
      <nav class="jw-gnb__nav">
        <VBtn
          v-for="(menu, index) in gnbMenuList"
          :key="'gnb-' + index"
          :class="{
            active: route.path.includes(menu.path),
            avatar: menu.name === '일기장',
          }"
          @click.stop="menuActor(menu)"
        >
          <v-avatar
            v-if="menu.name === '일기장'"
            size="28"
            :color="!profileImg ? 'secondary' : undefined"
            :class="{
              default: !profileImg,
              active: route.path.includes(menu.path),
            }"
          >
            <VImg v-if="profileImg" :src="profileImg" />
            <VIcon v-else size="15" color="white">{{ menu.icon }}</VIcon>
          </v-avatar>
          <!-- 투데이 pc 상단 점 삭제 -->
          <!-- <v-badge v-else-if="menu.newCount && menu.name !== '더보기'" offset-x="3" offset-y="2" floating dot color="#FF3D00">
            <VIcon size="18">{{ menu.icon }}</VIcon>
          </v-badge> -->
          <VIcon v-else size="18">{{ menu.icon }}</VIcon>
          <!-- <span class="ml-1">{{ menu.name }}</span> -->
          <span class="ml-1">{{ $t(commonStore.cdMatcher(menu.name)) }}</span>
          <v-badge v-if="menu.name === '더보기' && menu.newCount > 0" :content="menu.newCount" inline color="#FF3D00" class="ml-2"></v-badge>
        </VBtn>
      </nav>
      <div>
        <v-btn variant="text" color="#666666" class="px-4" v-if="loginStatus" @click.stop="onClickLogout" data-ko="로그아웃">
          {{ $t("dwj.util.msg.001") }}
        </v-btn>
      </div>
    </div>
  </v-app-bar>
  <v-app-bar
    v-if="isWritePage"
    :height="isPc ? 50 : 55"
    :class="{
      'jw-header-pc': isPc,
      'jw-header-pc--write': isPc,
      'jw-app-header': !isPc,
      'jw-app-header--write': !isPc,
    }"
  >
    <div class="jw-gnb jw-gnb--wrap">
      <!-- <NuxtLink to="/today" class="jw-gnb__logo">
        <img src="~/assets/images/header-logo.svg" width="128" height="37" alt="Journal with Jesus logotype" class="jw-header-pc__logo" />
      </NuxtLink> -->
      <!-- <template v-slot:prepend> -->
      <!-- TODO: 이부분 pc일때 absolute로 가자 -->
      <div class="write-nav d-flex" style="min-width: 900px">
        <v-btn icon @click="router.back()">
          <v-icon>jwIcons:svg/arrow-left</v-icon>
        </v-btn>

        <div class="jw-write-toggle">
          <button @click="writeStore.state.writeToggle = 'KEYBOARD'" :class="{ active: writeStore.state.writeToggle === 'KEYBOARD' }">
            <VIcon size="32">jwIcons:svg/keyboard</VIcon>
          </button>
          <button
            @click="writeStore.state.writeToggle = 'HANDWRITING'"
            class="ml-3"
            :class="{ active: writeStore.state.writeToggle === 'HANDWRITING' }"
          >
            <VIcon size="24">jwIcons:svg/handwrite</VIcon>
            손글씨
          </button>
        </div>
        <v-spacer></v-spacer>
        <span class="jw-write-stauts"> {{ writeStore.state.statusText }} </span>
        <!-- <v-btn variant="text" class="jw-btn--reset px-4" @click="router.back()"> 취소 </v-btn> -->
        <!-- <v-btn variant="text" class="jw-btn--reset px-4" @click.stop="onClickCancelSubmit"
          >{{ statusText === '저장되었습니다' ? '완료' : '취소' }}
        </v-btn> -->
        <v-btn variant="flat" height="25" size="small" rounded color="primary" class="jw-btn--reset px-2 ml-2" @click.stop="submitWrite">완료</v-btn>
      </div>
    </div>
  </v-app-bar>
</template>
<script setup>
import { useAuthStore } from "~/store/auth";
import { useWriteStore } from "~/store/write";
import { isEmpty } from "~/utils/utils";
import { useDisplay } from "vuetify";
import { VIcon } from "vuetify/lib/components/index.mjs";
import { useCommonStore } from "~/store/common";

const authStore = useAuthStore();
const writeStore = useWriteStore();
const commonStore = useCommonStore();

const { smAndDown, xlAndUp } = useDisplay();
const isMobile = computed(() => smAndDown.value);
const isPc = computed(() => xlAndUp.value);

const route = useRoute();
const router = useRouter();
const isWritePage = computed(() => route.name == "write");

/* const isWritePage = computed(()=>rout) */

const loginStatus = computed(() => authStore.loginStatus);
const userInfo = computed(() => authStore.userInfo);

const profileImg = computed(() => {
  if (!isEmpty(userInfo.value.profileId)) {
    return `https://jwj.kr/upload/member/${userInfo.value.profileId}`;
  } else {
    //return `https://i.pravatar.cc/300`;
    //return "jwIcons:svg/user-solid";
    return false;
  }
});

// gnb 관련
const gnbMenuList = computed(() => {
  return [
    {
      name: "투데이",
      icon: "jwIcons:svg/menu-today",
      newCount: 1,
      path: "/today",
    },
    {
      name: "쓰기",
      icon: "jwIcons:svg/menu-write",
      newCount: 0,
      path: "/write",
    },
    {
      name: "일기장",
      icon: profileImg.value || "jwIcons:svg/user-solid",
      newCount: 2,
      path: "/diary",
    },
    {
      name: "나눔방",
      icon: "jwIcons:svg/menu-room",
      newCount: 2,
      path: "/room",
    },
    {
      name: "더보기",
      icon: "jwIcons:svg/menu-more",
      newCount: Number(authStore.userInfo.badgeCnt) || 0,
      path: "/more",
    },
  ];
});

function submitWrite() {
  writeStore.state.submitBtn = true;
}

async function onClickLogout() {
  await authStore.logoutActor();
  router.replace("/signin");
}

async function menuActor(obj) {
  await authStore.chkLoginStatusActor();
  if (loginStatus.value != true) {
    if (obj.name != "투데이") {
      return router.push("/signin?toLogin=true");
    }
    return false;
  }
  router.push(obj.path);
}
</script>
